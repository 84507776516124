/** @format */

.ad-banner {
  max-width: 100%;
  text-align: center;
  padding: 1.5rem;
  height: auto;
}

.ad-banner .banner-img {
  max-width: 80%;
  width: auto;
  height: auto;
  object-fit: contain;
  display: block;
  margin: auto;
}

.main-navBar {
  width: 100%;
  background-color: #0f19af;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

.main-navBar .logo {
  max-width: 150px;
  cursor: pointer;
}

.main-navBar .links {
  display: flex;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  column-gap: 1.5rem;
  align-items: center;
}

.main-navBar .links a {
  text-decoration: none;
  color: #fff;
  margin: 0;
}

.main-navBar .links .score-span {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.main-navBar .links .dropdown_nav a {
  color: rgb(13, 110, 253);
  text-decoration: underline;
}

.main-navBar .icons {
  display: flex;
  column-gap: 1.5rem;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.main-navBar .icons .fa-bars {
  display: none;
}

.max-container {
  max-width: 1000px;
  margin: auto;
  width: 100%;
}

.main-sidebar.offcanvas.offcanvas-start {
  background-color: #0f19af;
}

.main-sidebar .close-btn {
  display: flex;
  justify-content: flex-end;
}

.main-sidebar .close-btn .fa-xmark {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.main-sidebar .links {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main-sidebar .links li {
  color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
}

.main-sidebar .links li #dropdown-basic {
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0.8rem;
  font-weight: 600;
}

.main-sidebar .links .dropdown-item span.link {
  font-size: 0.8rem;
  font-weight: 600;
  color: rgb(13, 110, 253);
  text-decoration: underline;
}

.main-sidebar .links .icons {
  font-size: 16px;
  border: 1px solid #fff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
}

.matches-headline {
  background-color: #b3b3b3;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.a-link-homepage {
  background-color: #333333;
}

.a-link-homepage-other {
  background-color: #4a4a4a;
}

.title-other-homepage {
  font-size: 12px !important;
}
.title-other-homepage span {
  font-size: 12px !important;
  color: rgb(252, 1, 1);
}

.matches-headline a {
  min-width: 100px;
  border: 1px solid #fff;
  padding: 10px;
}

.matches-headline a.title {
  color: #fff;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.matches-headline .a-link {
  color: #fff;
  text-decoration: none;
}

.matches-headline .a-link h1 {
  font-size: 1rem;
  margin: 0;
}

.matches-headline .a-link h1 span {
  font-size: 10px;
}

.generic-slider {
  width: 100%;
}

.root-div {
  background-color: rgb(241 245 249);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.root-div .main-div {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.top_slider_card {
  width: 266px !important;
  height: auto;
  border-radius: 10px;

  background-color: #fff;
}

.top_slider_card_div1 {
  padding: 12px;
}

.slick-initialized {
  overflow: hidden;
}

.top_slider_card_div1_text {
  display: flex;
  align-items: center;
  gap: 4px;
}

.top_slider_card_div1_text > p:first-child {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  font-size: 10px;
  line-height: 16px;
  color: rgb(115, 115, 115);
  text-decoration-color: rgb(115, 115, 115);
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  background-color: rgb(255, 255, 255);
  width: 200px;
}

.top_slider_card_div1_text > p:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: rgb(66, 66, 66);
  padding-left: 8px;
  padding-right: 8px;
  font-size: 10px;
  color: rgb(255, 255, 255);
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  line-height: 20px;
}

.top_slider_card_div2_img {
  width: 17px;
  height: 17px;
  border-radius: 50%;
}

.top_slider_card_div2_text {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
}

.top_slider_card_div2_text11 {
  background-color: #0f19af;
  border-radius: 0px 0px 10px 10px;
}

.top_slider_card_div2_text11_text23 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
}

.top_slider_card_div2_text11_text23 > span {
  color: white;
}

.top_slider_card_div2_text11_text23 > span:hover {
  text-decoration: underline;
}

.top-slider-card-container {
  background-color: #e7e7e7;
  padding: 10px;
  overflow: hidden;
}

.livescore-component {
  padding: 0.5rem;
  background-color: #fff;
}

.livescore-component .heading {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.tabs-list {
  display: flex;
  gap: 3rem;
  align-items: center;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
}
.tabs-container2 {
  border-bottom: 1px solid #c7c8c9;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.tabs-list li {
  padding: 5px 0px;
  cursor: pointer;
  text-align: center;
}

.tabs-list li.active {
  color: #0f19af;
  font-weight: 600;
  border-bottom: 2px solid #0f19af;
}

.btns-tabs {
  display: flex;
  gap: 3rem;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
}

.btns-tabs button {
  width: 150px;
  border: 1px solid #dee2e6;
  border-radius: 50px;
  padding: 10px;
}

.btns-tabs button.active {
  background-color: #0f19af;
  color: #fff;
  font-weight: 600;

  @media screen and (max-width: 568px) {
    padding: 0.5rem 0.8rem;
  }
}

.btns-tabs button.not-active {
  @media screen and (max-width: 568px) {
    padding: 0.5rem 0.8rem;
  }
}

.match-heading-div {
  background-color: #e6e6e7;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1.5rem;
  padding: 20px;

  @media screen and (max-width: 568px) {
    justify-content: center;
    color: #000;
    font-weight: bold;
  }
}

.match-score-detail-container {
  display: flex;
  justify-content: center;
}

.margin-div {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.match-score-detail-container .all-container {
  max-width: 950px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  gap: 3rem;
  width: 100%;
}

.match-score-detail-container .all-container .left-section {
  width: 70%;
}

.match-score-detail-container .all-container .left-section .custom-card {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.match-score-detail-container
  .all-container
  .left-section
  .custom-card
  .heading {
  color: rgb(148 163 184);
}

.match-score-detail-container .all-container .left-section .custom-card .venue {
  color: rgb(148 163 184 / 1);
  margin: 0;
}

.match-score-detail-container
  .all-container
  .left-section
  .custom-card
  .blank-screen {
  background-color: #858584;
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  height: 120px;
}

.match-score-detail-container
  .all-container
  .left-section
  .custom-card
  .blank-screen
  .content {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 6rem;
  justify-content: space-between;
}

.match-score-detail-container
  .all-container
  .left-section
  .custom-card
  .blank-screen
  .content
  .main {
  flex: 1;
}

.match-score-detail-container .all-container .left-section .custom-card .links {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.match-score-detail-container
  .all-container
  .left-section
  .custom-card
  .links
  li {
  color: #0f19af;
  text-align: center;
  padding: 5px 20px;

  border-right: 2px solid #ddd;
}

.match-score-detail-container
  .all-container
  .left-section
  .custom-card
  .links
  li:last-child {
  border: none;
}

.match-score-detail-container .all-container .right-section {
  width: 30%;
}

.match-score-detail-container .all-container .right-section .card-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Footer_Div {
  background-color: #4a4b4b;
  color: white;
  font-size: 16px;
  text-align: left;
  width: 100%;
  padding: 3rem 2rem;
  padding-bottom: 0;
}

.footer_logo_div {
  display: flex;
  align-items: center;
}

.footer_logo {
  max-width: 220px;
  cursor: pointer;
}

.footer_copyright {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-top: 1rem;
  justify-content: center;
  border-top: 1px solid #d1d1d1;
}

.Footer_Div .main-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.future-series-container-head {
  background-color: #e6e6e7;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  padding: 20px 0px;
}

.future-series-container-boxes-container {
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
  padding-bottom: 3rem;
}

.future-series-container-boxes-container .boxes {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  padding: 3rem;
  background-color: #fff;
}

.future-series-container-boxes-container .boxes .box {
  display: flex;
  gap: 5rem;
  align-items: flex-start;
}

.future-series-container-boxes-container .boxes .box .month-container {
  max-width: 150px;
  font-weight: 600;
  width: 100%;
}

.future-series-container-boxes-container .boxes .box .series-container {
  max-width: 650px;
  width: 100%;
}

.cricket-schedule-day-container {
  display: flex;
  margin-top: 0.5rem;
  padding-bottom: 3rem;
  justify-content: center;
}

.cricket-schedule-day-container .main-section {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 0;
  gap: 10px;
}

.cricket-schedule-day-container .main-section .grid-card {
  display: flex;
  gap: 20px;
  margin: 10px 0;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .grid-card1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

.cricket-schedule-day-container .main-section .grid-card .item-content {
  display: flex;
  gap: 2rem;
}

.cricket-schedule-day-container
  .main-section
  .grid-card
  .item-content
  h1.first-head {
  width: 35%;
}

.cricket-schedule-day-container .main-section .grid-card .item-content div {
  flex: 1;
}

.series-archieve-container {
  width: 100%;
  padding: 3rem 0;
  background-color: #fff;
}

.Login-Section {
  height: 700px;
}

.Login-Section .heading {
  text-align: center;
  font-weight: 600;
  font-size: 1.875rem;
  margin-top: 2.5rem;
}

.Login-Section .term-conditions {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  align-items: center;
  gap: 0.5rem;
}

.overflow-div {
  max-width: 100%;
  overflow-y: auto;
}

.box-shadow-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.test-team-country-img {
  max-width: 60px;
}

.general-banner-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.point-table {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #fff;
  align-items: flex-start;
}

.news-card {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
}

.news-card img {
  width: 180px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.news-card .content {
  flex: 1;
}

.news-card .content .heading {
  color: rgb(148 163 184);
}

.cricket-news-container {
  display: flex;
  margin-top: 3rem;
  justify-content: center;
  padding-bottom: 3rem;
}

.top-banner {
  width: 100%;
  max-height: 300px;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.ad-side-banner {
  width: 100%;
  max-height: 550px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.special-box-container {
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.special-box-container .heading {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.special-box-container .box-cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.special-box-container .box img {
  width: 100%;
  display: block;
  margin: auto;
  object-fit: contain;
  object-position: center;
}

.special-box-container .box .title {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.special-box-container .box .description {
  font-size: 14px;
  font-weight: 600;
  color: rgb(139 140 141);
  margin: 0;
}

.current-series-container {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 1.5rem;
}

.current-series-container p.heading {
  font-size: 16px;
  color: #000;
  font-weight: 900;
}

.current-series-container .main-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  margin-top: 0.5rem;
  padding: 0;
}

.current-series-container .main-container li {
  list-style: none;
  color: rgb(31 41 55);
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.ranking-series-container {
  padding: 20px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.5rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ranking-table thead tr th {
  background-color: #e7e7e7;
  text-align: center;
  font-weight: 600;
}

.ranking-table thead tr th:first-child {
  border-radius: 15px 0px 0px 0px;
}

.ranking-table thead tr th:last-child {
  border-radius: 0px 15px 0px 0px;
}

.ranking-table tbody tr td {
  text-align: center;
}

.schedule-squad {
  margin-top: 0.5rem;
  padding: 1rem;
  display: flex;
}

@media (max-width: 768px) {
  .schedule-squad {
    padding: 0;
  }

  .hide-on-mobile,
  .main-navBar .links {
    display: none;
  }

  .main-navBar .icons .fa-bars {
    display: block;
  }

  .matches-headline {
    display: none;
  }

  .tabs-list li {
    font-size: 12px;
  }

  .btns-tabs {
    gap: 10px;
  }

  .btns-tabs button {
    font-size: 12px;
    width: auto;
    padding: 10px 30px;
  }

  .match-heading-div {
    font-size: 15px;
  }

  .match-score-detail-container .all-container {
    flex-direction: column;
    max-width: 100%;
    gap: 20px;
  }

  .match-score-detail-container .all-container .left-section {
    width: 100%;
  }

  .match-score-detail-container
    .all-container
    .left-section
    .custom-card
    .venue,
  .cricket-schedule-day-container
    .main-section
    .grid-card
    .item-content
    h1.location-name,
  .future-series-container-boxes-container .boxes .box .series-container p,
  .future-series-container-boxes-container .boxes .box .month-container {
    font-size: 14px;
  }

  .match-score-detail-container
    .all-container
    .left-section
    .custom-card
    .blank-screen {
    max-width: 100%;
  }

  .match-score-detail-container
    .all-container
    .left-section
    .custom-card
    .blank-screen
    .content
    .main,
  .match-score-detail-container .all-container .right-section {
    width: 100%;
  }

  .match-score-detail-container
    .all-container
    .left-section
    .custom-card
    .links
    li,
  .match-score-detail-container
    .all-container
    .left-section
    .custom-card
    .blank-screen
    .text-here {
    font-size: 12px;
    font-weight: 600;
  }

  .margin-div {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .Footer_Div div {
    width: 100%;
  }

  .Footer_Div .main-container {
    flex-wrap: wrap;
    gap: 20px;
  }

  .future-series-container-boxes-container .boxes {
    padding: 1rem;
  }

  .future-series-container-boxes-container .boxes .box {
    flex-direction: column;
    gap: 10px;
  }

  .future-series-container-boxes-container .boxes {
    gap: 10px;
  }

  .cricket-schedule-day-container .main-section .grid-card {
    flex-direction: column;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
  }

  .cricket-schedule-day-container .main-section .grid-card:last-child {
    border: none;
  }

  .cricket-schedule-day-container .main-section .grid-card .item-content {
    flex-direction: column;
    gap: 10px;
  }

  .cricket-schedule-day-container
    .main-section
    .grid-card
    .item-content
    h1.first-head {
    width: 100%;
  }

  .cricket-schedule-day-container .main-section .match-heading-div {
    margin-top: 0;
  }

  .xs-small-text {
    font-size: 12px !important;
  }

  .small-text {
    font-size: 14px !important;
  }

  .medium-text {
    font-size: 16px !important;
  }

  .large-text {
    font-size: 18px !important;
  }

  .footer_logo {
    max-width: 150px;
  }

  .Login-Section .heading {
    margin-top: 20px;
  }

  .Login-Section .term-conditions {
    margin-top: 10px;
  }

  .Login-Section {
    height: 500px;
  }

  .bold-font {
    font-weight: 600;
  }

  .full-width {
    width: 100% !important;
  }

  .medium-padding {
    padding: 20px !important;
  }

  .column-flex {
    flex-direction: column;
  }

  .small-padding {
    padding: 10px !important;
  }

  .zero-margin {
    margin: 0 !important;
  }

  .upper-margin-medium {
    margin-top: 20px;
  }

  .display-none {
    display: none;
  }

  .auto-height {
    height: auto;
  }

  .feacturePosts {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .zero-padding {
    padding: 0 !important;
  }

  .test-team-country-img {
    max-width: 40px;
  }

  .topBarCommentary {
    margin-top: 1rem;
  }

  .point-table {
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .news-card img {
    width: 120px;
  }

  .cricket-news-container {
    margin-top: 0;
    padding-bottom: 0;
  }

  .cricket-news-container .main-container {
    gap: 10px !important;
  }

  .top-banner {
    max-height: 150px;
  }

  .special-box-container .box-cont {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .special-box-container .box-cont .box {
    width: 45%;
  }

  .special-box-container .box-cont .box img {
    width: 160px;
    height: 160px;
  }

  .current-series-container .main-container li {
    font-size: 12px;
  }

  .current-series-container p.heading {
    font-size: 14px;
  }

  .ad-side-banner {
    max-height: 300px;
    object-position: top;
  }
}

.boss {
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
}

.live-Scores-tabs {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    background-color: #0f19af;
    height: 40px;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
      color: white;
      padding-top: 0.5rem;
      cursor: pointer;
    }

    .active-live-tab {
      cursor: pointer;

      border-bottom: 1px solid white;
    }
  }
}

.active-live-tab {
  cursor: pointer;
  color: #0f19af;
}

.inactive-live-tab {
  color: #000;
  cursor: pointer;
}

.item-content2 {
  display: flex;
  gap: 2rem;
  align-items: center;
  color: black;

  @media screen and (max-width: 568px) {
    justify-content: space-between;
    background-color: #cfe0da;
    color: #2e2e35;
    font-weight: 500;
  }
}

.item-content2 .text-22 {
  display: none;

  @media screen and (max-width: 568px) {
    display: block;
    font-size: large;
    font-weight: bold;
  }
}

@media screen and (max-width: 568px) {
  .colorChange {
    color: #e0a10c;
  }
  .disapperClass {
    display: none;
  }
}

.showClass {
  display: none;

  @media screen and (max-width: 568px) {
    display: block;
  }
}

.overflowXDir {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overflowXDir::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 568px) {
  .score-card-mobile-res {
    width: 95%;
    margin: auto;
  }
}

.accordiaon-scorecard {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  font-weight: 500;
  font-size: 14px;
  gap: 1rem;
}

.accordion-button {
  background-color: #0f19af !important;
  color: white !important;
}
.accordion-collapse .collapse {
  background-color: white !important  
  ;
  color: #000 !important;
}

.accordion-body {
  padding: 10px 0% !important;
}

scoreCard-res-content-table {
  margin-bottom: 1rem;
}

.scoreCard-res-content {
  color: #d6a666 !important;
}
.score-card-mobile-res th {
  background-color: #ecebeb !important;
}

.scoreCard-res-content-name {
  display: flex;
  flex-direction: column;
  line-height: normal;
}

.scoreCard-res-content-name > span:first-child {
  font-weight: 500;
  font-size: 14px;
  color: #1866dd !important;
}

.scoreCard-res-content-name > span:first-child:hover,
.scoreCard-res-content-name > span:last-child:hover {
  text-decoration: underline;
  cursor: pointer;
}

.scoreCard-res-content-name > span:last-child {
  font-weight: 500;
  font-size: 14px;
  color: #777a83 !important;
}

.scoreCard-res-content-total-score > td {
  background-color: #cce9e2 !important;
  padding-bottom: 0rem !important;
}

.scoreCard-res-content-name-didnot {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  row-gap: 0.3rem;
}

.scoreCard-res-content-name-didnot > span:first-child {
  font-weight: 700;
  color: #000 !important;
}

.scoreCard-res-content-name-didnot > span:last-child {
  font-weight: normal;
  color: #1866dd !important;
}

.text-color-blueRK {
  font-weight: normal;
  color: #1866dd !important;
  font-size: 14px;
  cursor: pointer;
}

.text-color-blueRK:hover {
  text-decoration: underline;
}

.text-color-blueRK1 {
  font-weight: bold;
  color: #000 !important;
  font-size: 14px;
}

.text-color-blueRK2 {
  font-weight: normal;
  color: #000 !important;
  font-size: 14px;
}

.hideClass {
  display: block;
  @media screen and (max-width: 568px) {
    display: none;
  }
}
.gali-gali-har-mod-text {
  margin-left: 2rem;
  font-size: 14px;
}

@media screen and (max-width: 568px) {
  .gali-gali-har-mod {
    display: flex;
    flex-direction: column;
  }

  .gali-gali-har-mod-text {
    width: 100%;
    margin-left: 0rem;
  }

  #links-res {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    overflow: scroll;
  }
  #links-res li {
    cursor: pointer;
  }
}

.text-editor-container table {
  width: 100% !important;
  table-layout: auto !important; 
}

.text-editor-container {
  overflow-x: auto !important; 
}

.stadium_name{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.5rem;

}


.stadium_name_text{
  width: 100% !important;
  font-size: 23px;
  margin-bottom: 10px
  ;
padding: 2rem 1rem;
border: 1px solid #dee2e6;  
border-radius: 0.2rem;
width: 95%;

margin: auto;
margin-bottom: 1.5rem;}