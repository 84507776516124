/** @format */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arimo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.myFont {
  font-family: "Arimo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.App {
  font-family: "Arimo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.terms_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.UseFlexMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  color: white;
  width: 100%;
}

.flexContainer {
  border: 1px solid black;
  color: white;
}

.fantasyTips {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 2rem;
  max-width: 600px;
  align-items: center;
  row-gap: 1rem;
}

.fantasyTips > div:first-child > img {
  width: 100%;
  max-height: 150px;
  border-radius: 10px;
}

.boxShadowFantsyTips {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.homePageSlider {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  border-radius: 10px;
}

.homePageSlider > div:first-child > p:first-child {
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.homePageSlider2 {
  background-color: rgb(14, 24, 175);
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 10px 10px;
}

.homePageSlider2 > div:nth-child(2) {
  color: white;
  font-size: 0.8rem;
  display: flex;
  gap: 20px;
  padding: 0.2rem 0.8rem;
  padding-top: 0.7rem;
  padding-bottom: 0.1rem;
}

.navbar {
  max-width: 980px;
  margin: 0 auto;
  border: 1px solid black;
}

.navbar-top-banner {
  max-height: 96px;
  background-color: #b3b3b3;
}

.navbar-top-banner-image {
  max-height: 96px;
}

.navBar {
  width: 1000px;
  margin: 0 auto;
  height: 61px;
  background-color: #0f19af;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.navBar_content {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 26px;
  max-width: 150px;
  cursor: pointer;
}

.navBar_links {
  display: flex;
  column-gap: 1.5rem;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
}

.navBar_links > a,
.no1 {
  cursor: pointer;
  padding-top: 1.8rem;
  text-decoration: none;
  color: white;
  font-weight: 700;
}

.navBar_links > p {
  cursor: pointer;
  padding-top: 1.8rem;
}

.navBar_links > p:hover {
  color: #b3b3b3;
}

.navBar_icons {
  display: flex;
  column-gap: 1.5rem;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  align-items: center;
  padding-top: 0.8rem;
  cursor: pointer;
}

.no-border-radius {
  border-radius: 0 !important;
  margin-top: 0.5rem !important;
}

.no-border-radius-text {
  color: black !important;
  font-weight: bold !important;
}

.no-border-radius-text1 {
  color: #0f19af !important;
  font-weight: bold !important;
  text-decoration: 2px underline #0f19af !important;
}

.feacturePosts {
  width: 650px;
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.feacturePosts_div {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 4px;
}

.middleBannerBig {
  width: 650px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.middleBannerImage {
  padding: 10px;
  width: 90%;
  margin: auto;
}

.middleBannerImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  max-height: 239px;
}

.middleBannerText {
  width: 90%;
  margin: auto;
  border-radius: 10px;
}

.middleBannerText > p:first-child {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #000;
}

.middleBannerText > p:last-child {
  font-size: 14px;
  color: #929394;
}

.editorPick {
  border-radius: 10px;
}

.matchList {
  border: 1px solid white;
  text-align: center;
  height: 48px;
  padding: 0 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.4rem;
}

.matchList:hover {
  background-color: #aca2a2;
  cursor: pointer;
}

.matchList > p {
  font-size: 14px;
  padding-top: 0.7rem;
  font-weight: 500;
}

.topBarCommentary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin-top: 3rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.topBarCommentary > p {
  font-size: 14px;
  font-weight: 500;
  color: #0f19af;
  cursor: pointer;
  border-radius: 4px;
}

.player-name {
  font-size: 40px;
  line-height: 42px;
  margin: 0px;
  color: rgb(34, 34, 34);
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(255, 255, 255);
}

.player-country {
  font-size: 18px;
  line-height: 24px;
  margin: 0px;
  color: rgb(102, 102, 102);
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(255, 255, 255);
}

.player-profile {
  display: flex;
  gap: 2rem;
}

.left-container {
  width: 323.391px;
  box-sizing: border-box;
  display: block;
  float: left;
  min-height: 1px;
  color: rgb(34, 34, 34);
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  font-size: 14px;
  line-height: 21px;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(245, 245, 245);
  font-weight: 700;
  font-size: 16px;
  margin-top: 2rem;
  padding: 2rem;
}

.left-text {
  width: 117.344px;
  box-sizing: border-box;
  display: block;
  float: left;
  min-height: 1px;
  padding: 10px 0px 5px;
  font-weight: 700;
  color: rgb(34, 34, 34);
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  font-size: 14px;
  line-height: 21px;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(245, 245, 245);
  color: #000;
}

.right-text {
  width: 176.031px;
  box-sizing: border-box;
  display: block;
  float: left;
  min-height: 1px;
  padding: 10px 0px 5px;
  color: rgb(34, 34, 34);
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  font-size: 14px;
  line-height: 21px;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(245, 245, 245);
}

.commentryData {
  width: 307.9px;
  box-sizing: border-box;
  display: block;
  float: left;
  min-height: 1px;
  padding: 0px 0px 10px;
  color: rgb(34, 34, 34);
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  font-size: 14px;
  line-height: 21px;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(255, 255, 255);
}

.CommentartyData1 {
  font-size: 17px;
  color: rgb(102, 102, 102);
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  line-height: 18px;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(255, 255, 255);
}

.CommentartyData2 {
  color: rgb(34, 34, 34);
  cursor: pointer;
  text-decoration: underline solid rgb(34, 34, 34);
  outline: rgb(34, 34, 34) none 0px;
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  font-size: 17px;
  line-height: 21px;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(255, 255, 255);
}

.abc {
  padding-right: 5px;
  width: 236.7px;
  text-align: left;
}

.right-container {
  padding: 15px;
  line-height: 24px;
  color: rgb(34, 34, 34);
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  font-size: 14px;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(255, 255, 255);
}

.lef-co-header {
  text-align: right;
  padding: 3px 0px;
  font-size: 12px;
  font-weight: 700;
  color: #000;
  background-color: #f5f5f5;
}

.lef-co-header1 {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 5px;
  line-height: 24px;
  color: rgb(34, 34, 34);
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(255, 255, 255);
}

.commentryDataClass {
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(221, 221, 221);
  overflow: hidden;
  padding: 8px;
  font-size: 13px;
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  display: flex;
  gap: 2rem;
}

.commentryDataClass2 {
  box-sizing: border-box;
  font-size: 13px;
  font-family: helvetica, "Segoe UI", Arial, sans-serif;
  cursor: default;
  background-color: rgb(255, 255, 255);
}

.stats_div {
  padding: 5px;
  margin-bottom: 10px;
}

.stats_div > p:first-child {
  background-color: #4b4b4a;
  color: white;
  font-size: 14px;
  padding: 8px 10px;
}

.statsDivSpantag {
  color: #d1d1d1;
  font-weight: bold;
  padding: 6px 10px;
  font-size: 14px;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 5px;
  cursor: pointer;
}

.statsDivSpantag1 {
  font-weight: normal;
  padding: 6px 10px;
  font-size: 14px;
  border-bottom: 1px solid rgb(34, 34, 34);
  padding-bottom: 5px;
  background-color: #d1d1d1;
  color: black;
}

.statsDivSpantag123 > span:hover {
  background-color: #d1d1d1;
  color: black;
}

.statsDivSpantag123 > span {
  color: black;
  font-weight: normal;
  padding: 6px 10px;
  font-size: 14px;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 5px;
}



.mobileCssNavbar {
  display: block;

  @media screen and (max-width: 468px) {
    display: none;
  }
}

.mobileCssNavbar1 {
  display: none;

  @media screen and (max-width: 468px) {
    display: block;
  }
}


